import React from "react";
import "./Employe.css";
import jihad from "../../Images/Image/jihad.jpeg";
import mahmud from "../../Images/Image/mahmud.jpeg";
import hossain from "../../Images/Image/md_hossain.jpeg";
import azim from "../../Images/Image/md_azim.jpeg";
import hasan from "../../Images/Image/md_hasan.jpeg";
import noimage from "../../Images/Image/no_image.jpg";

const Employe = () => {
  return (
    <section class="teams" id="teams">
      <div class="max-width">
        <h2 class="teams-title">Our Teams</h2>
        <div class="carousel owl-carousel">
          <div class="card">
            <div class="box">
              <img src={jihad} alt="" />
              <div class="text">
                <strong> Ahsanul Hoque Jihad</strong>
              </div>
              <p>Founder</p>
            </div>
          </div>

          <div class="card">
            <div class="box">
              <img src={mahmud} alt="" />
              <div class="text">
                <strong>Abdullah Al Mahmud</strong>
              </div>
              <p>Web Developer</p>
            </div>
          </div>

          <div class="card">
            <div class="box">
              <img src={hossain} alt="" />
              <div class="text">
                <strong>MD HOSSAIN</strong>
              </div>
              <p>Document Controller</p>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img src={azim} alt="" />
              <div class="text">
                <strong>MD ABDUL AZIM</strong>
              </div>
              <p>Project Coordinator</p>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img src={hasan} alt="" />
              <div class="text">
                <strong>MD HASAN</strong>
              </div>
              <p>Representative</p>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img src={noimage} alt="" />
              <div class="text">
                <strong>FAYE LIZETTE</strong>
              </div>
              <p>Marketing Executive</p>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img src={noimage} alt="" />
              <div class="text">
                <strong>IRFAN AHMED</strong>
              </div>
              <p>Marketing Manager</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Employe;
