import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "../../Images/Banner/banner1.jpg";
import banner2 from "../../Images/Banner/banner2.jpg";
import banner3 from "../../Images/Banner/banner3.jpg";

const Slider = () => {
  return (
    <Carousel className="mt-5 mx-4 border border-4 rounded shadow-lg">
      <Carousel.Item>
        <img className="d-block w-100" src={banner1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner3} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
